import React from 'react';
import Box from './Box';
import './App.css';
import './global.css';
import { SpeedInsights } from "@vercel/speed-insights/react"

function App() {
  return (
    <div className="App">
      <Box />
      <SpeedInsights />
    </div>
  );
}

export default App;