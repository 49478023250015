import React, { useState, useEffect } from "react";
import "./Box.css";

const Box = () => {

  const initialMinutes =
    JSON.parse(localStorage.getItem("minutes")) || new Array(1440).fill(false);
  const [minutes, setMinutes] = useState(initialMinutes);
  const [working, setWorking] = useState(false);
  const [currentMinute, setCurrentMinute] = useState(
    new Date().getHours() * 60 + new Date().getMinutes()
  );
  const [darkMode, setDarkMode] = useState(false);
  const [lifetime, setLifetime] = useState(
    JSON.parse(localStorage.getItem("lifetime")) || { hours: 0, minutes: 0 }
  );

  useEffect(() => {
    localStorage.setItem("minutes", JSON.stringify(minutes));
  }, [minutes]);

  useEffect(() => {
    localStorage.setItem("lifetime", JSON.stringify(lifetime));
  }, [lifetime]);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const newCurrentMinute = now.getHours() * 60 + now.getMinutes();
      if (newCurrentMinute < currentMinute) {
        updateLifetimeAndResetMinutes();
      }
      setCurrentMinute(newCurrentMinute);
      if (working) {
        setMinutes((prevMinutes) => {
          const newMinutes = [...prevMinutes];
          newMinutes[currentMinute] = true;
          return newMinutes;
        });
      }
    }, 60000);
    return () => clearInterval(interval);
  }, [working, currentMinute, minutes]);

  const updateLifetimeAndResetMinutes = () => {
    const minutesWorked = minutes.filter((minute) => minute).length;
    const additionalHours = Math.floor(minutesWorked / 60);
    const additionalMinutes = minutesWorked % 60;
  
    setLifetime((prevLifetime) => {
      const totalMinutes = prevLifetime.minutes + additionalMinutes;
      const overflowHours = Math.floor(totalMinutes / 60);
      const remainingMinutes = totalMinutes % 60;
  
      return {
        hours: prevLifetime.hours + additionalHours + overflowHours,
        minutes: remainingMinutes,
      };
    });
  
    setMinutes(new Array(1440).fill(false));
  };

  const reset = () => {
    updateLifetimeAndResetMinutes();
    setWorking(false);
  };

  const toggleWorking = () => {
    setWorking(!working);
    if (!working) {
      const now = new Date();
      const newCurrentMinute = now.getHours() * 60 + now.getMinutes();
      setCurrentMinute(newCurrentMinute);
      setMinutes((prevMinutes) => {
        const newMinutes = [...prevMinutes];
        newMinutes[newCurrentMinute] = true;
        return newMinutes;
      });
    }
  };

  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const [infoActive, setInfoActive] = useState(false);

// Add a new function for toggling the info div
const toggleInfo = () => {
  setInfoActive(!infoActive);
};

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const totalMinutesWorked = minutes.filter((minute) => minute).length;
  const hoursWorked = Math.floor(totalMinutesWorked / 60);
  const remainingMinutes = totalMinutesWorked % 60;

  return (
    <div className={darkMode ? "dark-mode" : ""}>
      <h1 className="heading">
        <span className="green">b</span>
        <span onClick={toggleDarkMode} style={{ cursor: "pointer" }}>
          0
        </span>
        <span className="green">X</span>
      </h1>

      <br />
      <div className="minutes-container">
        {minutes.map((minute, index) => (
          <div
            key={index}
            className={`minute ${minute ? "working" : ""}`}
          ></div>
        ))}
      </div>
      <br />
      <button
        className="work-button"
        onClick={toggleWorking}
        style={{ position: "absolute", left: 13 }}
      >
        {working ? "Stop Working" : "Start Working"}
      </button>
      <br />
      <br />
      <div className="stat">
        <div className="hover-container">
          <div>
            {working ? <span className="blinking-dot"></span> : ""}{" "}
            {hoursWorked}h {remainingMinutes}m
          </div>
          <div className="lifetime">
            Lifetime {lifetime.hours}h {lifetime.minutes}m
          </div>
          <button
            className="reset-button"
            onClick={reset}
            style={{ position: "absolute", right: 13 }}
          >
            ♻
          </button>
        </div>
      </div>
      <br />
      <div className={`overlay-control info-menu-container ${menuActive ? 'is-hidden' : ''}`}>
        <ul id="info-menu" className={menuActive ? 'is-visible' : ''}>
        <li onClick={toggleInfo}><a href="#">box</a></li>
          <li><a>by.arjun</a></li>
        </ul>
        <button className={`button-menu ${menuActive ? 'is-active' : ''}`} onClick={toggleMenu}>
          <div className="menu-icon">
            <div className="bar bar-top"></div>
            <div className="bar bar-middle"></div>
            <div className="bar bar-bottom"></div>
          </div>
        </button>
      </div>
      {infoActive && (
      <div className="info-div">
        <div className="info-content">
          <p>Visualize your days' productivity at a single glance</p>
          <p className="light-green">1440 boxes for each minute of the day</p>
          <p>break procrastination</p>
          <p className="light-green">fill the green boxes</p>
          <p>(design inspired by github heatmap)</p>
          <p className="light-green">reset button: resets boxes + adds current time to lifetime</p>
          <button onClick={toggleInfo} className="light-green">X</button>
        </div>
      </div>
    )}
    </div>
  );
};

export default Box;
